import React, { useState, useEffect, useCallback, useMemo } from "react";

// tools
import styled, { keyframes, css } from "styled-components";

// globals
import colors from "../../../globals/colors";

// images
import PrevOffImg from "../../../assets/images/about/ic_left_off_80px.png";
import PrevOnImg from "../../../assets/images/about/ic_left_on_80px.png";
import NextOffImg from "../../../assets/images/about/ic_right_off_80px.png";
import NextOnImg from "../../../assets/images/about/ic_right_on_80px.png";

const NEWS1 = [
  {
    name: "파이낸셜 뉴스",
    date: "2024-12-20",
    title: "페이워크, 중소벤처기업부 '팁스(TIPS)' 프로그램 선정",
    url: "https://www.fnnews.com/news/202412200852481184",
  },
  {
    name: "IT비즈뉴스",
    date: "2024-12-03",
    title:
      "소상공인 미수금 계산기 페이워크, 프라이머사제 파트너스 후속 투자유치",
    url: "https://www.itbiznews.com/news/articleView.html?idxno=153850",
  },
  {
    name: "대한경제",
    date: "2024-09-30",
    title: "페이워크, 제34회 핀테크 데모데이 우수상 수상",
    url: "https://www.dnews.co.kr/uhtml/view.jsp?idxno=202409301043411420834",
  },
  {
    name: "중앙일보",
    date: "2024-08-30",
    title: "페이워크, 투명한 정산 시스템 구축 ‘소상공인 불안 해소’",
    url: "https://www.joongang.co.kr/article/25274274",
  },
  {
    name: "매일일보",
    date: "2024-08-29",
    title:
      "하나은행, 개인사업자 대상 페이워크㈜ 간편 업무정산관리 서비스 제공 실시",
    url: "https://www.m-i.kr/news/articleView.html?idxno=1154519",
  },
  {
    name: "IT조선",
    date: "2024-01-12",
    title: "하나은행, '하나원큐 애자일랩 15기' 스타트업 7곳 지원",
    url: "https://it.chosun.com/news/articleView.html?idxno=2023092108247",
  },
  {
    name: "조선일보",
    date: "2024-01-10",
    title:
      "[쫌아는기자들] 페이워크, ‘돈 못받고 답답한’ 1인기업, N잡러의 페인포인트 해결",
    url: "https://www.chosun.com/economy/smb-venture/2024/01/10/V2ZYMT42QRG4LEDTPPHW2VTLGM/",
  },
  {
    name: "테크월드",
    date: "2023-12-18",
    title: "페이워크, 제34회 핀테크 데모데이 우수상 수상",
    url: "https://www.epnc.co.kr/news/articleView.html?idxno=239377",
  },
  {
    name: "비즈니스코리아",
    date: "2023-12-04",
    title: "페이워크 - KCEE, 일본 대리점 계약 체결",
    url: "https://www.businesskorea.co.kr/news/articleView.html?idxno=206982",
  },
  {
    name: "와우테일",
    date: "2023-12-04",
    title: "페이워크, 서울형 R&D 기술사업화 지원사업 대상기업 최종 선정",
    url: "https://wowtale.net/2023/12/04/67645/",
  },
  {
    name: "데일리경제",
    date: "2023-09-21",
    title: "페이워크-오늘의작업장, 서비스 교류 위한 MOU 양해각서 체결",
    url: "http://www.kdpress.co.kr/news/articleView.html?idxno=122836",
  },
  {
    name: "데일리시큐",
    date: "2023-07-27",
    title: "페이워크, LGU+ 비즈마켓 입점",
    url: "https://www.dailysecu.com/news/articleView.html?idxno=148128",
  },
  {
    name: "플래텀",
    date: "2022-01-17",
    title: "스파크랩 18기 액셀러레이팅 프로그램 참여하는 14개 스타트업",
    url: "https://platum.kr/archives/179097",
  },
  {
    name: "매일경제",
    date: "2021-11-11",
    title: "스파크랩, '2021 뉴 콘텐츠랩' 온라인 데모데이 종료",
    url: "https://www.mk.co.kr/news/business/view/2021/11/1066649/",
  },
  {
    name: "매거진 한경",
    date: "2021-10-13",
    title: "프리랜서·소상공인 이제는 일에만 집중할 수 있다",
    url: "https://magazine.hankyung.com/job-joy/article/202110133577d",
  },
  {
    name: "데일리경제",
    date: "2021-08-06",
    title: "페이워크(주), 중기부 스마트 서비스 지원사업 선정",
    url: "http://www.kdpress.co.kr/news/articleView.html?idxno=106674",
  },
  {
    name: "벤처스퀘어",
    date: "2021-06-28",
    title: "프리랜서의 계약 '이후'를 도와주는 곳, 페이워크",
    url: "https://www.venturesquare.net/831704",
  },
];

const News = ({ newsRef, isNews, innerWidth }) => {
  const [page, setPage] = useState(0);
  const [indexedNewsData, setIndexedNewsData] = useState();
  const [newsData, setNewsData] = useState();

  const newsPerPage = useMemo(() => {
    if (innerWidth > 1500) {
      return 4;
    } else if (innerWidth <= 1500 && innerWidth > 1200) {
      return 3;
    }
    return 2;
  }, [innerWidth]);

  // 첫번째 페이지인지, 마지막 페이지인지 확인
  const isFirstPage = useMemo(() => {
    return newsData && newsData[0]?.no === 1;
  }, [newsData]);
  const isLastPage = useMemo(() => {
    return newsData && newsData[0]?.no + newsPerPage > indexedNewsData.length;
  }, [newsData, indexedNewsData, newsPerPage]);

  const titleFontSize =
    innerWidth > 1300
      ? 42
      : innerWidth > 744
      ? 42 - 8 * ((1300 - innerWidth) / (1300 - 744))
      : 34 - 10 * ((744 - innerWidth) / (744 - 375));
  const arrowSize =
    innerWidth > 1300
      ? 80
      : innerWidth >= 744
      ? 80 - 30 * ((1300 - innerWidth) / (1300 - 744))
      : 50 - 10 * ((743 - innerWidth) / (743 - 375));
  const arrowHeightSize =
    innerWidth >= 744 ? 80 : 80 - 20 * ((743 - innerWidth) / (743 - 375));
  const mobileNewsHeight =
    innerWidth >= 744 ? 96 : 96 - 24 * ((743 - innerWidth) / (743 - 375));
  const mobileNewsSideMargin =
    innerWidth >= 744 ? 40 : 40 - 20 * ((743 - innerWidth) / (743 - 375));
  const mobileTitleNewsFontSize =
    innerWidth >= 744 ? 20 : 20 - 4 * ((743 - innerWidth) / (743 - 375));
  const mobileTitleNewsLineHeight =
    innerWidth >= 744 ? 32 : 32 - 8 * ((743 - innerWidth) / (743 - 375));
  const sideMargin =
    innerWidth > 1300 ? 60 : 60 - 30 * ((1300 - innerWidth) / (1300 - 744));
  const contentsMarginTop =
    innerWidth > 1300
      ? 100
      : innerWidth > 744
      ? 100 - 60 * ((1300 - innerWidth) / (1300 - 744))
      : 60 - 20 * ((744 - innerWidth) / (744 - 375));
  const newsWidth =
    innerWidth > 1300 ? 270 : 270 - 7 * ((1300 - innerWidth) / (1300 - 744));
  const newsWidthTM =
    innerWidth > 744 ? 263 : 263 - 105 * ((744 - innerWidth) / (744 - 375));
  const newsHeightTM =
    innerWidth > 744 ? 96 : 96 - 24 * ((744 - innerWidth) / (744 - 375));
  const containerMarginTopTM =
    innerWidth > 744 ? 180 : 180 - 100 * ((744 - innerWidth) / (744 - 375));

  // initial data setting
  useEffect(() => {
    // auto indexing
    let tempIndexedNewsData = NEWS1.map((news, idx) => ({
      ...news,
      no: idx + 1,
    }));
    setIndexedNewsData(tempIndexedNewsData);
    setNewsData(tempIndexedNewsData.slice(0, newsPerPage));
  }, []);

  // news page data setting
  useEffect(() => {
    // 초기화 되지않았다면 return
    if (!indexedNewsData) return;

    //newsPerPage 만큼의 데이터를 가져옴
    const sliceNews = indexedNewsData.slice(
      page * newsPerPage,
      page * newsPerPage + newsPerPage
    );

    return setNewsData(sliceNews);
  }, [page, newsPerPage, indexedNewsData]);

  const onChangeNext = useCallback(() => {
    if (innerWidth > 1500) {
      const sliceNews = indexedNewsData.slice(page + 1, page + 5);
      setNewsData(sliceNews);
    }

    if (innerWidth <= 1500 && innerWidth > 1200) {
      const sliceNews = indexedNewsData.slice(page + 1, page + 4);
      setNewsData(sliceNews);
    }

    if (innerWidth <= 1200) {
      const sliceNews = indexedNewsData.slice(page + 1, page + 3);
      setNewsData(sliceNews);
    }
    setPage(page + 1);
  }, [page, indexedNewsData, innerWidth]);

  const onChangePrev = useCallback(() => {
    let sliceNews = [];
    if (innerWidth > 1500) {
      sliceNews = indexedNewsData.slice(page - 1, page + 3);
    }

    if (innerWidth <= 1500 && innerWidth > 1200) {
      sliceNews = indexedNewsData.slice(page - 1, page + 2);
    }

    if (innerWidth < 1200) {
      sliceNews = indexedNewsData.slice(page - 1, page + 1);
    }

    setNewsData(sliceNews);
    setPage(page - 1);
  }, [page, indexedNewsData, innerWidth]);

  return (
    <Container ref={newsRef} containerMarginTopTM={containerMarginTopTM}>
      <Wrapper isNews={isNews}>
        <Header titleFontSize={titleFontSize} isNews={isNews}>
          언론에서 바라보는 페이워크
        </Header>

        <Content
          isNews={isNews}
          contentsMarginTop={contentsMarginTop}
          isMobile={innerWidth < 744}
        >
          <PrevArrow
            src={isFirstPage ? PrevOffImg : PrevOnImg}
            onClick={() => !isFirstPage && onChangePrev()}
            isFirst={isFirstPage}
            arrowSize={arrowSize}
            sideMargin={sideMargin}
            isMobile={innerWidth < 744}
          />
          <NewsList start={page} isMobile={innerWidth < 744}>
            {newsData?.map((news, idx) => (
              <NewsWrapper
                key={idx}
                lastCheck={
                  (innerWidth > 1500 && idx === 3) ||
                  (innerWidth <= 1500 && innerWidth > 1200 && idx === 2) ||
                  (innerWidth <= 1200 && idx === 1)
                }
                mobileNewsSideMargin={mobileNewsSideMargin}
                onClick={() => window.open(news.url, "_blank")}
              >
                <BarNews />
                <TitleNews
                  mobileTitleNewsFontSize={mobileTitleNewsFontSize}
                  mobileTitleNewsLineHeight={mobileTitleNewsLineHeight}
                  mobileNewsHeight={mobileNewsHeight}
                  newsWidth={newsWidth}
                  newsWidthTM={newsWidthTM}
                  newsHeightTM={newsHeightTM}
                >
                  {news.title}
                </TitleNews>
                <InfoNews>
                  <NameInfo>{news.name}</NameInfo>
                  <DateInfo>{news.date}</DateInfo>
                </InfoNews>
              </NewsWrapper>
            ))}
          </NewsList>
          <NextArrow
            src={isLastPage ? NextOffImg : NextOnImg}
            onClick={() => !isLastPage && onChangeNext()}
            isLast={isLastPage}
            arrowSize={arrowSize}
            sideMargin={sideMargin}
            isMobile={innerWidth < 744}
          />
          <MobileArrowWrapper isMobile={innerWidth < 744}>
            <MobilePrevArrow
              isMobile={innerWidth < 744}
              isFirst={isFirstPage}
              src={isFirstPage ? PrevOffImg : PrevOnImg}
              arrowSize={arrowSize}
              arrowHeightSize={arrowHeightSize}
              onClick={() => !isFirstPage && onChangePrev()}
            />
            <MobileNextArrow
              isMobile={innerWidth < 744}
              isLast={isLastPage}
              src={isLastPage ? NextOffImg : NextOnImg}
              arrowSize={arrowSize}
              arrowHeightSize={arrowHeightSize}
              onClick={() => !isLastPage && onChangeNext()}
            />
          </MobileArrowWrapper>
        </Content>
      </Wrapper>
    </Container>
  );
};

export default News;

const Container = styled.div`
  margin-top: 150px;
  min-height: 492px;
  background: ${colors.Body00};
  width: 100%;
  display: flex;
  justify-content: center;

  @media ${(props) => props.theme.tablet} {
    margin-top: ${(props) => `${props.containerMarginTopTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    margin-top: 80px;
    width: 375px;
    display: block;
  }
`;

const Wrapper = styled.div`
  margin: 100px 10px 150px;
  width: 1480px;
  display: flex;
  flex-direction: column;

  @media (max-width: 1480px) {
    width: 100%;
  }

  @media ${(props) => props.theme.mobile} {
    margin: 60px 0px 120px;
    width: auto;
  }
`;

const FadeIn = () => keyframes`
  0% {
    opacity: 0;
    transform: translateY(200px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
    transition: all ease-out 2s;
  }
`;

const Header = styled.div`
  display: ${(props) => (props.isNews ? "flex" : "none")};
  justify-content: center;
  align-items: center;
  color: #ffffff;
  font-size: ${(props) => `${props.titleFontSize}px`};
  font-weight: 600;

  ${(props) =>
    props.isNews &&
    css`
      animation: ${FadeIn} 2s;
    `};

  @media ${(props) => props.theme.mobile} {
    font-size: 24px;
  }
`;

const Content = styled.div`
  display: ${(props) => (props.isNews ? "flex" : "none")};
  flex-direction: ${(props) => (props.isMobile ? "column" : "row")};
  margin-top: ${(props) => `${props.contentsMarginTop}px`};
  justify-content: center;

  ${(props) =>
    props.isNews &&
    css`
      animation: ${FadeIn} 2s;
    `};

  @media ${(props) => props.theme.tablet} {
    margin-top: 40px;
  }
`;

const PrevArrow = styled.img`
  width: ${(props) => `${props.arrowSize}px`};
  height: 80px;
  margin-right: ${(props) => `${props.sideMargin}px`};
  margin-top: 49px;
  cursor: ${(props) => (props.isFirst ? "unset" : "pointer")};
  display: ${(props) => props.isMobile && "none"};

  @media ${(props) => props.theme.tablet} {
    margin-right: 10px;
  }

  draggble: false;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const NextArrow = styled.img`
  width: ${(props) => `${props.arrowSize}px`};
  height: 80px;
  margin-left: ${(props) => `${props.sideMargin}px`};
  margin-top: 49px;
  cursor: ${(props) => (props.isLast ? "unset" : "pointer")};
  display: ${(props) => props.isMobile && "none"};

  @media ${(props) => props.theme.tablet} {
    margin-left: 10px;
  }

  draggble: false;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const MobileArrowWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const MobilePrevArrow = styled.img`
  /* width: ${(props) => `${props.arrowSize}px`};
  height: ${(props) => `${props.arrowHeightSize}px`}; */
  width: 40px;
  height: 60px;
  margin-top: 40px;
  cursor: ${(props) => (props.isFirst ? "unset" : "pointer")};
  display: ${(props) => (props.isMobile ? "block" : "none")};

  draggble: false;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const MobileNextArrow = styled.img`
  /* width: ${(props) => `${props.arrowSize}px`};
  height: ${(props) => `${props.arrowHeightSize}px`}; */
  width: 40px;
  height: 60px;
  margin-top: 40px;
  cursor: ${(props) => (props.isLast ? "unset" : "pointer")};
  display: ${(props) => (props.isMobile ? "block" : "none")};

  draggble: false;
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`;

const NewsList = styled.div`
  display: flex;
  max-width: 1480px;
  justify-content: ${(props) => props.isMobile && "center"};

  @media ${(props) => props.theme.tablet} {
    margin: 0 20px;
  }
`;

const NewsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${(props) => (props.lastCheck ? "0px" : "40px")};
  cursor: pointer;

  @media ${(props) => props.theme.tablet} {
    margin-right: ${(props) =>
      props.lastCheck ? "0px" : `${props.mobileNewsSideMargin}px`};
  }

  @media ${(props) => props.theme.mobile} {
    /* margin-right: ${(props) => (props.lastCheck ? "0px" : `20px`)}; */
    margin-right: ${(props) => (props.lastCheck ? "0px" : `5px`)};
  }
`;

const BarNews = styled.div`
  width: 30px;
  height: 4px;
  background: #ffffff;
  border-radius: 2px;
  margin-bottom: 30px;
  transition: 0.5s;

  ${NewsWrapper}:hover & {
    width: 135px;
    transition: 0.5s;

    @media ${(props) => props.theme.mobile} {
      width: 80px;
    }
  }
`;

const TitleNews = styled.div`
  /* width: 270px; */
  width: ${(props) => `${props.newsWidth}px`};
  height: 96px;
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  color: #ffffff;

  @media ${(props) => props.theme.tablet} {
    font-size: ${(props) => `${props.mobileTitleNewsFontSize}px`};
    line-height: ${(props) => `${props.mobileTitleNewsLineHeight}px`};
    width: ${(props) => `${props.newsWidthTM}px`};
    height: ${(props) => `${props.newsHeightTM}px`};
  }

  @media ${(props) => props.theme.mobile} {
    width: 158px;
    height: 72px;
    font-size: 16px;
    line-height: 24px;
  }
`;

const InfoNews = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  color: ${colors.G4};
`;

const NameInfo = styled.div`
  font-size: 16px;
  font-weight: 600;
`;

const DateInfo = styled.div`
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
`;
